import React from 'react'
import {Text,Box,SimpleGrid, Heading,AspectRatio} from '@chakra-ui/react'
import {motion} from 'framer-motion'
import { StaticImage} from 'gatsby-plugin-image'
import MeinSeo from '../components/seo'



const StolpersteinVideos =()=>{

 
    return(
      <motion.div
      initial={{opacity:0,scale:.7,paddingBottom:"100px"}}
      animate={{opacity:1,scale:1}}
      transition={{duration:.8,delay:.1}}
      >
         <MeinSeo title="Stolperstein in Bacharach" description='Filme und Bilder der Verlegung der Stolpersteine'/>
      
<Heading variant="Titel">Stolpersteine, Bacharach, 14. August 2014</Heading>
<SimpleGrid mb="10" gap="4" columns={[1,1,2]} maxWidth="1000">
      <Box>
          <Text variant="solid">Am 22. August 2014 wurden in Bacharach zum ersten Mal vier Stolpersteine durch den Künstler Gunter Demnig verlegt. Die Inschriften auf der glänzenden Messingplatte sollen an die durch die Nationalsozialisten deportierten jüdischen Geschwister Wilhelm und Emma Keller und Antonie Herzberg erinnern sowie an Heinrich Paff, der der „Euthanasie“ zum Opfer fiel.</Text>
          <Text variant="solid" pb="2">Viele tausend Steine hat der in Berlin geborene Künstler Gunter Demnig schon im Boden von beinahe tausend deutschen Städten und Gemeinden und in zahlreichen europäischen Städten verlegt, aber auch in der Ukraine, in Russland und der Schweiz. Bacharach ist die 978. Stadt.
          (Dagmar Aversano-Schreiber)
          </Text>
          <Box mt="3">
<StaticImage
  src="../images/stolper/keber-verlegung.jpeg"
  alt="Keber-Stolperstein"
  layout='constrained'
 
  />
  <Text variant="Bild">Gedenktafel in der Langstraße</Text>
</Box>
<Box>
<StaticImage
  src="../images/stolper/demnig.jpeg"
  alt="Der Künstler Günther Demmnig"
  layout='constrained'
  
  
/>
<Text variant="Bild">Künstler Günther Demnig</Text>
</Box>



          

      </Box>
    <Box>  
          <Box mt={[0,4]}>
            <AspectRatio maxW='460px' ratio={1.9}>
                <iframe 
                  title="Rede von Peter Keber"
                  src="https://player.vimeo.com/video/532183842?h=bc6fc89e20"
                  allowFullScreen
                />
            
            </AspectRatio>
            <Text pl="3" variant='Bild'>Peter Keber</Text>
            </Box>
              <Box>

                <AspectRatio maxW='460px'  ratio={1.9}>
                    <iframe  
                      title="Rede von Karl-Heinz Schleis"
                      src="https://player.vimeo.com/video/532194098?h=105f018d1c"
                      allowFullScreen
                    />
                </AspectRatio>
                <Text pl="3" variant='Bild'>Karl-Heinz Schleis</Text>
              </Box>
                <Box>
                <AspectRatio maxW='460px' ratio={1.9}>
                      <iframe
                        title="Rede von Peter Keber"
                        src="https://player.vimeo.com/video/532186391?h=f0ef39c668"
                        allowFullScreen
                      />
                  </AspectRatio>
                  <Text pl="3" variant='Bild'>Rede von Peter Keber</Text>
                  </Box>
 
 
</Box>
</SimpleGrid> 


</motion.div>
    )
 

}
export default StolpersteinVideos
